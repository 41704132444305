.screen-register .ant-page-header-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
}

.container-form-register {
  padding-bottom: 20px;
}

.container-form-register h1 {
  font-size: 16px;
  margin-bottom: 0;
}

.container-form-register {

  h2 {
    font-size: 14px;
    margin-bottom: 1em;
    color: rgba(0, 0, 0, 0.45);
    font-weight: normal;
  }

  .error-label {
    font-size: 14px;
    margin-bottom: 1em;
    color: red;
    font-weight: normal;
  }

}

.container-form-register .form-actions .form-actions-link {
  float: left;
  margin-top: 3px;
  font-size: 1.2em;
}

.container-form-register .form-actions .ant-form-item {
  text-align: right;
}

.container-form-register .tos-row {
  margin-bottom: 6px;
}

.link-terms-of-service {
  margin-top: 15px;
  text-align: center;
}

.already-code {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
}

.new-code {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
}

.registration-step3-helper-list {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 24px;
}

.registration-step3-helper-list li.ant-list-item {
  padding-left: 0;
  padding-right: 0;
}

.registration-step3-helper-list li.ant-list-item button {
  display: none;
}

.registration-step3-helper-list li.ant-list-item:hover button {
  display: block;
}

.registration-step3-helper-list .list-actions {
  float: right;
  text-align: right;
}

.container-form-register .site-collapse-custom-collapse {
  background: none;
}

.container-form-register .site-collapse-custom-collapse .site-collapse-custom-panel {
  border-bottom: none;
}

.container-form-register .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 0;
  padding: 0;
}

.container-form-register .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0 0 6px 0px;
}

.container-form-register .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 4px 0 0 0;
}

.screen-register .logo img {
  display: block;
  margin: 0 auto 25px auto;
  height: 35px;
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .screen-register .logo img {
    // height: 42px;
    margin-top: -77px;
    margin-bottom: 40px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}
@primary-color: #4E77B8;