.doc-file {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
  transition: border-color 0.3s ease;

  .doc-file-icon {
    font-size: 48px;
    color: #676767;
  }

  p {
    margin: 0;
    color: #5399dd;
  }
}

.doc-footer {
  padding: 6px;
  display: flex;
  justify-content: flex-end;

  .btn-edit {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    border: 1px solid #5399dd;
    border-radius: 4px;
    padding: 7px 15px;
    color: #5399dd;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #5399dd;
      color: #fff;

      > span {
        color: #fff;
      }
    }
  }
}

.doc-upload{
  .ant-upload-list-item{
    border-color: #22c55e;
  }
}
@primary-color: #4E77B8;