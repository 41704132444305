@import '~antd/es/style/themes/default.less';

html,
body,
#root,
#App {
  height: 100%;
}

main.ant-layout-content {
  display: flex;
  flex-direction: column;
}

body .ant-layout,
body .ant-layout-footer {
  background: #fff;
}

.ant-layout-header {
  line-height: 48px;
}

.ant-layout-header .logo {
  float: left;
  margin: 0 16px 0 16px;
  font-weight: bold;
}

.img-logo {
  height: 30px;
}

.ant-layout-header .main-nav-controls .ant-btn,
.ant-layout-header .mobile-nav-controls .ant-btn {
  box-shadow: none;
}

.ant-layout-header .main-nav-controls .ant-btn .ant-badge .anticon {
  // color: rgba(0, 0, 0, 0.85);
  color: #4e77b8;
}

.react-icon {
  // color: rgba(0, 0, 0, 0.85);
  color: #4e77b8;
}

.ant-layout-header .main-nav-controls .ant-select-show-search {
  vertical-align: top;
}

.ant-layout-header .main-nav-controls .ant-menu-overflow {
  display: block; // antd changed this to 'flex' at some point, which broke layout slightly so putting it back to 'block'
}

.page-header {
  line-height: 18px;
}

.page-header .care-guide-selector {
  display: inline-block;
  margin-top: 8px;
}

.care-guide-selector {
  margin-left: 5px;
}

.care-guide-selector .ant-btn {
  border: none;
  box-shadow: none;
  font-size: 20px;
  color: #4e77b8;
}

.user-menu-dropdown {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.user-menu-dropdown .ant-menu-vertical > .ant-menu-item {
  height: 28px;
  line-height: 28px;
}

#content-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.page-header {
  font-size: 20px;
  margin: 0;
}

.ant-divider-horizontal.page-header-divider {
  margin: 15px 0;
  border-width: 5px;
}

.dashboard-content h2.section-header {
  font-size: 17px;
}

.content-sidebar .ant-card-head {
  border-bottom: none;
}

#App .content-sidebar .ant-card-head-title {
  font-size: 17px;
  padding-bottom: 0;
}

#App .content-sidebar .ant-card-extra {
  font-size: 12px;
  padding: 0;
}

.content-sidebar .ant-card-body {
  padding-top: 0;
}

.content-sidebar .ant-list-item-meta-title {
  font-weight: normal;
  margin-bottom: 0;
}

.content-sidebar .ant-list-sm {
  padding-bottom: 12px;
}

.content-sidebar .ant-list-sm .ant-list-item {
  padding: 8px 0;
}

.content-sidebar .ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.content-sidebar
  .ant-list-split.event-invitations-list
  .ant-list-item:last-child {
  border-bottom: none;
}

.content-sidebar .ant-list-split.event-invitations-list,
.content-sidebar .ant-list-split.event-invitations-list .ant-list-items {
  padding-bottom: 0;
}

.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
}

.section-sub-menu {
  display: inline-block;
  margin: -66px auto 17px auto;
  background: none;
}

.ant-layout-content > .ant-divider-horizontal {
  margin: 16px 0;
}

.ant-card {
  margin-bottom: 15px;
}

body .ant-card-bordered {
  border-color: rgba(0, 0, 0, 0.15);
}

.ant-list-item .ant-card {
  margin-bottom: 0;
}

.ant-card-head-title button {
  margin-left: 10px;
}

.ant-typography.section-title {
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 0 24px;
}

.ql-bubble {
  border: 1px solid #ccc;
}

.drag-handle {
  width: 32px;
  height: 32px;
  background-color: #f1f1f1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-handle .anticon {
  color: #777;
}

// typography

body a,
body .ant-btn > a:only-child,
body .ant-btn > span {
  color: #4e77b8;
}

body .ant-btn.ant-btn-primary > span,
body .ant-btn > span.ant-avatar {
  color: #fff;
}

body .ant-btn.ant-btn-primary.ant-btn-background-ghost > span {
  color: #4e77b8;
}

body .ant-btn.ant-btn-dangerous > span {
  color: #bb2118;
}

body .ant-btn.ant-btn-danger > span {
  color: #fff;
}

body .ant-btn.ant-btn-dangerous.ant-btn-primary > span {
  color: #fff;
}

body a:hover {
  color: #6e8cb7;
}

body .ant-form-item-label > label,
body .ant-checkbox-wrapper {
  color: rgba(0, 0, 0, 0.75);
}

body .ant-card-meta-description {
  color: rgba(0, 0, 0, 0.75);
}

body .center {
  text-align: center;
}

.ant-layout-footer {
  font-size: 0.9em;
}

// pagination

.ant-list .ant-list-pagination {
  text-align: center;
}

// menus

.ant-drawer-body .drawer-menu-icon {
  margin-right: 5px;
}

// forms

.form-group.checkbox-list .ant-checkbox-group-item {
  display: block;
}

body .ant-picker-input > input::placeholder,
body .ant-input::placeholder,
body .ant-select-multiple .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.55);
}

body
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: none;
}

body
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after,
.required-mark {
  display: inline-block;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

body .ant-form-item .ant-form-item-label {
  padding-bottom: 2px;
}

form .form-actions:last-of-type .ant-form-item {
  margin-bottom: 0;
}

form .hidden-inputs {
  display: none;
}

// alerts

.alerts-dropdown {
  width: 350px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.alerts-dropdown .ant-card {
  margin-bottom: 0;
}

.alerts-dropdown .ant-card .ant-list-vertical .ant-list-item-meta-title {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
}

.alerts-dropdown .ant-card .ant-list-vertical .ant-list-item-meta-description {
  font-size: 12px;
}

.alerts-dropdown .ant-card .ant-list-vertical .ant-list-item-meta {
  margin-bottom: 6px;
}

.alerts-dropdown .ant-card .ant-list-item-action {
  margin-left: 0;
}

.alerts-dropdown .ant-card .ant-list-item-action li {
  padding: 0;
}

.alerts-dropdown .ant-card .ant-list-item-action li .ant-btn {
  box-shadow: none;
  padding: 4px 10px;
}

.alerts-dropdown .ant-card .ant-list-sm .ant-list-item {
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
}

.alerts-dropdown .ant-list-item .ant-list-item-meta-title .alert-title-new {
  color: #ff4d4f;
}

.alerts-dropdown .ant-list-item .ant-list-item-meta-description {
  font-size: 0.95em;
}

.alerts-dropdown .ant-list-item .ant-list-item-meta-description p {
  margin-bottom: 6px;
}

.alerts-dropdown .ant-list-item .ant-list-item-meta-description p:last-child {
  margin-bottom: 0;
}

.alerts-dropdown .load-more {
  text-align: center;
  margin: 12px 0 0 0;
}

.alerts-dropdown a {
  color: rgba(0, 0, 0, 0.45);
}

// care guide

.care-guide-segments .ant-anchor-link {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: 600;
  padding: 0;
}

.care-guide-segments .ant-anchor-link a {
  padding: 16px 12px;
}

.care-guide-segments .ant-anchor-link.create-link {
  border-bottom: none;
  font-weight: normal;
}

.care-guide-menu .ant-anchor-ink::before {
  width: 0;
}

.care-guide-summary-form .care-guide-image {
  margin: 5px auto;
}

.care-guide-summary-form table {
  min-width: 315px;
}

.care-guide-summary-form table td {
  vertical-align: top;
}

.care-guide-image-form .ant-upload.ant-upload-select-picture-card {
  width: 164px;
  height: 164px;
  margin-right: 0;
  border-color: #c6c6c6;
}

.care-guide-image-container {
  padding: 1px;
}

.care-guide-image-form .ant-space {
  width: 100%;
}

.care-guide-image-button .ant-upload.ant-upload-select,
.care-guide-image-button .ant-upload {
  display: block;
}

.care-guide-summary-form table td:first-child {
  color: rgba(0, 0, 0, 0.45);
}

.care-guide-summary-form table .care-guide-summary-value {
  padding: 1px 5px;
}

.care-guide-summary-form .ant-form-item {
  margin-bottom: 0;
}

.care-guide-summary-form .ant-form-item-control-input,
.care-guide-summary-form .ant-form-item textarea {
  min-height: auto;
}

.care-guide-summary-form .ant-form-item textarea {
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  border: 1px dashed #c6c6c6;
  padding: 0px 4px;
  background-color: #fafafa;
}

.care-guide-summary-form .ant-card-head-title {
  font-size: 1.5em;
}

.care-guide-summary-form .care-guide-summary-members {
  margin-top: 24px;
}

.care-guide-segment-cards .ant-card-bordered,
.care-guide-segment-cards .ant-card-bordered .ant-card-head {
  border-color: rgba(0, 0, 0, 0.15);
}

.ant-form-item.segment-input-order .ant-form-item-label {
  padding-bottom: 2px;
}

.segment-content-form .ant-card-head-title,
.segment-create-form .segment-input-name .ant-input,
.segment-content-form .segment-input-name .ant-input {
  font-size: 1.3em;
}

.segment-content-form .segment-input-name,
.segment-create-form .segment-input-name {
  margin: 0 10px 0 0;
}

.segment-content-form .segment-input-order,
.segment-create-form .segment-input-order {
  margin: 0;
}

.segment-content-form .segment-name {
  padding: 1px 5px;
}

.segment-content-form .segment-input-name input,
.segment-create-form .segment-input-name input {
  border: none;
  padding: 0;
  font-weight: 500;
  border: 1px dashed #c6c6c6;
  padding: 0px 4px;
  background-color: #fafafa;
}

.segment-content-form .segment-input-name input:focus,
.segment-create-form .segment-input-name input:focus {
  box-shadow: none;
}

.segment-content-form .segment-input-name .ant-form-item-control-input,
.segment-create-form .segment-input-name .ant-form-item-control-input,
.segment-content-form .segment-input-order .ant-form-item-control-input,
.segment-create-form .segment-input-order .ant-form-item-control-input {
  min-height: 0;
}

.segment-content-form .segment-input-order .ant-form-item-label label,
.segment-create-form .segment-input-order .ant-form-item-label label {
  height: auto;
}

.segment-content-form .segment-content {
  padding: 1px 5px;
}

.segment-content-form .ant-form-item {
  margin-bottom: 0;
}

#content-main .segment-content-form .ql-container {
  border: 1px dashed #c6c6c6;
  padding: 0px 4px;
  background-color: #fafafa;
}

.segment-content-form .ql-bubble .ql-editor.ql-blank::before {
  left: 4px;
}

.care-guide-form .nested-list {
  margin-left: 40px;
}

.care-guide-segment-spacer .ant-space-item:nth-of-type(2) {
  width: 100%;
}

.care-guide-segments .create-link a {
  color: rgba(0, 0, 0, 0.45);
}

.care-guide-form .quill {
  margin-bottom: 24px;
}

.care-guide-form .field-type-table-column-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.care-guide-form .field-type-table-column-list > .ant-space {
  margin-right: 8px;
}

.care-guide-form .field-type-table-column-list .ant-form-item {
  margin-bottom: 0;
}

.care-guide-form .field-type-table-row-list .ant-space {
  display: flex;
  flex-direction: row;
}

.care-guide-form .field-type-table-row-list .ant-form-item {
  margin-bottom: 8px;
}

.care-guide-invitation-form .ant-form-inline .ant-form-item {
  margin-bottom: 24px;
}

.table-editor .ant-table-wrapper {
  margin-bottom: 16px;
}

.table-editor .ant-form-item {
  margin-bottom: 0;
}

.table-editor .ant-form-item.table-column-label-input {
  margin-bottom: 6px;
}

.table-editor .add-col-btn,
.table-editor .del-row-btn {
  float: right;
}

.care-guide-picker {
  display: inline-block;
  margin-left: 5px;
}

.care-guide-picker .ant-avatar {
  margin-top: -4px;
}

.care-guide-picker .ant-btn {
  border: none;
  box-shadow: none;
  font-size: 18px;
  color: #4e77b8;
  margin-top: -3px;
}

.adl-table,
.med-table {
  max-width: 900px;
  border-bottom: 10px #666 solid;
}

.adl-table-editor {
  max-width: 910px;
  padding: 1px 5px;
}

.med-table-editor {
  max-width: 960px;
  padding: 1px 5px;
}

.adl-table .ant-table-thead > tr > th,
.adl-table-editor .ant-table-thead > tr > th,
.med-table .ant-table-thead > tr > th,
.med-table-editor .ant-table-thead > tr > th {
  background-color: #666;
  color: #fff;
  font-weight: 600;
}

.adl-table .ant-table-wrapper:first-child .ant-table-thead > tr > th,
.adl-table-editor .ant-table-wrapper:first-child .ant-table-thead > tr > th,
.med-table .ant-table-wrapper:first-child .ant-table-thead > tr > th,
.med-table-editor .ant-table-wrapper:first-child .ant-table-thead > tr > th {
  background-color: #4078bd;
}

.adl-table .ant-table-tbody > tr:nth-child(odd),
.adl-table-editor .ant-table-tbody > tr:nth-child(odd),
.med-table .ant-table-tbody > tr:nth-child(odd),
.med-table-editor .ant-table-tbody > tr:nth-child(odd) {
  background-color: #d5e2f2;
}

// remove table row hover effect
.adl-table
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.adl-table
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.adl-table .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.adl-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.adl-table-editor
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.adl-table-editor
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.adl-table-editor .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.adl-table-editor
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row)
  > td {
  background: unset;
}

// remove table row hover effect
.med-table
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.med-table
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.med-table .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.med-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.med-table-editor
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.med-table-editor
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row)
  > td,
.med-table-editor .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.med-table-editor
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row)
  > td {
  background: unset;
}

.adl-table-editor th,
.med-table-editor th,
.adl-table-editor td,
.med-table-editor td {
  vertical-align: top;
}

.adl-table-editor td:last-of-type,
.med-table-editor td:last-of-type {
  vertical-align: middle;
}

.med-table-editor td .ant-form-item-explain {
  text-align: left;
}

.med-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table,
.med-table-editor
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table {
  border-top: none;
}

.med-table-editor
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th.med-table-delete-col,
.med-table-editor
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td.med-table-delete-col {
  background-color: #fff;
  border-color: #fff;
  border-right-color: #fff;
}

.adl-table-editor .ant-table-cell textarea,
.med-table-editor .ant-table-cell textarea,
.med-table-editor .ant-table-cell input,
.med-table-editor .ant-table-cell .ant-select .ant-select-selector {
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  border: 1px dashed #c6c6c6;
  padding: 0px 4px;
  background-color: #fafafa;
  min-height: auto;
}

.adl-table-editor .ant-table-cell textarea,
.med-table-editor .ant-table-cell textarea,
.med-table-editor .ant-table-cell input {
  margin: -1px -5px -1px -5px;
}

.med-table-editor
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr:nth-child(odd)
  > td {
  padding: 7px 8px;
}

.med-table-editor .ant-table-cell .ant-select .ant-select-selector {
  height: 24px;
}

.med-table-editor
  .ant-table-cell
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 22px;
}

.med-table-editor .med-table-add-row-button {
  margin: 5px 0 15px 0;
}

.med-table-editor .ant-table-cell .med-table-input-medication,
.med-table-editor .ant-table-cell .med-table-span-medication {
  font-weight: 600;
}

.med-table-editor .ant-table-cell .ant-input.med-table-input-name,
.med-table-editor
  .ant-table-cell
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.med-table-input-name,
.med-table-editor
  .ant-table-cell
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover.med-table-input-name {
  background-color: #666;
  color: #fff;
  font-weight: 600;
}

.med-table-editor
  .ant-table-wrapper:first-child
  .ant-table-thead
  > tr
  > th
  .med-table-input-name {
  background-color: #4078bd;
}

.med-table-editor .ant-table-cell .med-table-input-medication,
.med-table-editor .ant-table-cell .med-table-input-dose,
.med-table-editor .ant-table-cell .med-table-input-time .ant-select-selector,
.med-table-editor
  .ant-table-cell
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.med-table-input-medication,
.med-table-editor
  .ant-table-cell
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover.med-table-input-medication,
.med-table-editor
  .ant-table-cell
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input.med-table-input-dose,
.med-table-editor
  .ant-table-cell
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover.med-table-input-dose {
  background-color: #d5e2f2;
}

.med-table tr.ant-table-placeholder,
.med-table-editor tr.ant-table-placeholder {
  display: none;
}

// editor

#content-main .ql-container,
.ant-modal .ql-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
}

#content-main .ql-container p,
.ant-modal .ql-container p {
  margin-bottom: 1em;
  line-height: 1.5715;
}

#content-main .ql-container p:last-child,
.ant-modal .ql-container p:last-child {
  margin-bottom: 0;
}

.segment-content-form .ql-container {
  border: none;
}

.segment-content-form .ql-container .ql-editor {
  padding: 0;
}

.ql-container .ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.55);
}

.quill {
  background: #fff;
}

// journal

.journal-posts-screen .content-sidebar {
  display: none;
}

.journal-posts-screen
  .inline-filter
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box,
.journal-posts-screen
  .inline-filter
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box
  .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
}

.journal-posts-screen
  .inline-filter
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box
  .ant-card,
.journal-posts-screen
  .inline-filter
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box
  .ant-card-body {
  margin-bottom: 0;
  padding-bottom: 0;
}

.journal-post-form .ant-form-item {
  margin-bottom: 0;
}

.btn-create-journal {
  float: right;
}

.journal-post-form .ant-form-item-label b {
  padding-right: 10px;
}

.journal-post-form .ant-form-inline {
  flex-direction: column;
}

.journal-post-form .ql-bubble {
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 8px 0;
}

.journal-post-form .ql-bubble .ql-editor {
  padding-left: 0;
  min-height: 100px;
}

.journal-post-form .ql-bubble .ql-editor.ql-blank::before {
  left: 0;
}

.journal-post-form .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0;
}

.journal-post-form .journal-post-btn-submit {
  margin-right: 10px;
}

.journal-post-form .journal-tags-container {
  margin-bottom: 15px;
}

.journal-post-form .ant-upload-list-picture {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 999;
}

.journal-post-form .ant-upload-list-picture .ant-upload-list-picture-container {
  margin-right: 8px;
}

.journal-post-form
  .ant-upload-list-picture
  .ant-upload-list-item
  .ant-upload-list-item-name {
  display: none;
}

.ant-modal .journal-post-form .journal-tags-input-container {
  margin-bottom: 16px;
}

.ant-modal .journal-reply-form .ant-form-inline {
  flex-direction: column;
}

.journal-posts-list li.ant-list-item:first-of-type {
  padding-top: 0;
}

.journal-detail .journal-summary {
  background: #f9f9f9;
}

.journal-summary .extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
}

.journal-summary .ant-card-meta-title {
  margin-bottom: 0;
}

.journal-summary .journal-post .edited-label,
.journal-post-replies .ant-comment-content-detail .edited-label {
  color: #9d9d9d;
  margin-left: 6px;
  font-weight: 200;
}

.journal-summary .ant-card-meta-detail {
  margin-bottom: 10px;
}

.journal-summary .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0;
}

.journal-summary .ant-card-meta-description {
  font-size: 0.9em;
}

.journal-summary .journal-rating .label {
  font-weight: normal;
}

.journal-summary .journal-rating .rating-img {
  vertical-align: middle;
  font-size: 24px;
}

.journal-summary .journal-date {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}

.journal-summary .journal-rating {
  display: inline-block;
  margin-left: 16px;
  line-height: 18px;
}

.journal-summary .journal-rating span.label {
  color: rgba(0, 0, 0, 0.45);
}

.journal-emojis {
  margin-right: 15px;
  margin-bottom: 7px;
  padding-top: 2px;
  border-top: 1px solid #f0f0f0;
  float: left;
}

.journal-emojis:empty {
  margin-right: 0;
}

.journal-emojis a,
.journal-emojis a:hover {
  color: #888;
}

.journal-emojis .ant-space {
  margin-left: 8px;
}

.journal-emojis .emoji-count {
  padding-left: 8px;
}

.journal-emoji {
  font-size: 18px;
}

.journal-emojis .add-emoji {
  margin-top: 3px;
}

.journal-emojis .add-emoji span.inactive {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.journal-emojis .ant-btn span {
  color: #777;
}

.journal-emoji-tooltip .ant-btn svg {
  margin-top: 3px;
}

.journal-emojis .ant-btn .journal-emoji-svg svg {
  margin-bottom: -1px;
}

.journal-emojis .journal-emoji .journal-emoji-svg svg {
  margin-bottom: -3px;
}

.journal-reactions-modal .journal-emoji-svg svg {
  margin-bottom: -4px;
}

.journal-tags,
.journal-media {
  margin-bottom: 16px;
  padding-top: 6px;
}

.journal-media .ant-image {
  margin-right: 10px;
}

.journal-media .ant-image .ant-image-img {
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.journal-post-replies
  .ant-comment-content-author
  > span.ant-comment-content-author-time {
  color: #999;
}

.journal-post-replies
  span.ant-comment-content-author-time
  .ant-dropdown-trigger {
  margin-left: 10px;
}

.journal-post-replies .journal-reply-form .ant-avatar {
  float: left;
  margin-right: 12px;
}

.journal-post-replies .journal-reply-form .ant-btn {
  margin-left: 36px;
}

.journal-post-replies .journal-reply-form .ant-form-item-label {
  padding-bottom: 2px;
}

.journal-post-replies .journal-reply-form .ant-form-item-label label {
  color: rgba(0, 0, 0, 0.45);
}

.journal-post-replies .journal-reply-form .ant-form-item-label .anticon {
  padding-right: 5px;
}

.journal-post-replies .journal-reply-form .ql-container .ql-editor {
  padding: 8px;
}

.journal-post-replies .journal-reply-form .ql-container .ql-editor p {
  margin-bottom: 0.5em;
}

.ant-form-item.hidden-input {
  margin-bottom: 0;
}

.hidden-input .ant-form-item-control-input {
  min-height: 0;
}

.content-sidebar .journal-posts-filter-form {
  padding-top: 16px;
}

.ant-modal .journal-post-form .ant-form-item {
  margin-bottom: 0;
}

.journal-posts-filter-form .ratings-input .ant-tag-checkable {
  padding-top: 6px;
}

.journal-posts-screen .inline-filter {
  margin-bottom: 15px;
}

.journal-posts-screen .inline-filter .ant-col:last-child .ant-form-item {
  margin-bottom: 0;
}

.journal-posts-screen .inline-filter .ant-card-body {
  padding-bottom: 0;
}

// messages

.messages-panel {
  display: flex;
  flex: 1;
}

.messages-menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #001529;
}

.messages-menu-dropdown {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.messages-panel > .ant-col {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.messages-panel > .ant-col > .ant-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0;
}

.messages-panel .messages-toolbar {
  margin-bottom: 8px;
}

.message-thread-card {
  border-left: 1px solid #f0f0f0;
}

.message-thread-card > .ant-card-body,
.message-list-card > .ant-card-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.message-thread-card > .ant-card-body,
.messages-list {
  flex: 1 1 auto;
  overflow-y: scroll;
  height: 0;
}

.message-thread-card .message-thread-care-guide-image {
  float: left;
  margin-right: 15px;
}

.message-thread-card .message-thread-subject {
  white-space: normal;
  padding-right: 10px;
}

.message-thread-card .message-thread-subtitle {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.messages-list .message-date {
  font-size: 0.75rem;
  margin: 3px 0 0 10px;
  color: rgba(0, 0, 0, 0.45);
}

.messages-list .message-badge {
  float: right;
}

.messages-list .ant-list-item-meta-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.messages-list .ant-list-item-meta-title,
.messages-list .ant-list-item-meta-description {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messages-list.ant-list-split li.ant-list-item {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #f0f0f0;
}

.messages-list.ant-list-split
  .ant-list-items
  > a:last-child
  > li.ant-list-item {
  border-bottom: none;
}

.messages-list .ant-list-items > a > li {
  padding: 12px;
}

.messages-list .ant-list-items > a.active > li {
  background: #f0f0f0;
}

.message-thread-card
  .ant-card-small
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-head-title {
  padding: 0;
}

.message-thread-card
  .ant-card-small
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-head-title
  > .card-inner-title {
  padding: 13px 0;
}

.message-thread-card
  .ant-card-small
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-head-title
  > .card-inner-title:hover {
  cursor: pointer;
}

.message-detail .ant-comment-content-author > a,
.ant-comment-content-author > span.ant-comment-content-author-name {
  font-size: 14px;
}

.message-detail .ant-comment-content-author > a,
.message-detail
  .ant-comment-content-author
  > span.ant-comment-content-author-time {
  margin-left: auto;
  color: #999;
}

.message-detail .message-to {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.message-body p {
  padding-top: 10px;
  padding-bottom: 16px;
}

.message-body p:last-child {
  padding-bottom: 0;
}

.resource-description-body .ql-container.ql-snow {
  height: auto;
}

.resource-description-body .ql-editor {
  height: 300px;
  overflow-y: auto;
}

.resource-description {
  max-height: 300px;
  overflow-y: auto;
}

.resource-description p {
  padding-top: 10px;
  padding-bottom: 16px;
}

.resource-description p:last-child {
  padding-bottom: 0;
}

.message-compose-modal .ant-modal-body {
  padding-bottom: 0;
}

.message-form .care-guide-picker {
  float: right;
  margin-left: 15px;
}

// calendar

.calendar-view .cv-rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.calendar-view .cv-rbc-toolbar .cv-rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}

.calendar-view .cv-rbc-toolbar .cv-rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}

.calendar-view .rbc-time-column {
  height: auto; // week view was showing incorrectly
}

.calendar-view .cv-rbc-toolbar-mobile .cv-rbc-mobile-header {
  padding-bottom: 10px;
}

.calendar-view .cv-rbc-toolbar-mobile .cv-rbc-toolbar-label {
  text-align: center;
  font-size: 1.2em;
}

.calendar-view .cv-rbc-toolbar-mobile .cv-rbc-mobile-header .ant-btn {
  float: right;
}

.calendar-view .cv-rbc-toolbar-mobile .cv-rbc-btn-group {
  margin: 6px 0 10px 0;
  padding-top: 10px;
  border-top: 1px solid #d9d9d9;
}

.calendar-view .cv-rbc-toolbar-mobile .cv-rbc-btn-today {
  float: right;
}

.calendar-panel,
.calendar-panel > .ant-col,
.calendar-panel > .ant-col > .ant-card,
.calendar-panel > .ant-col > .ant-card > .ant-card-body {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.calendar-panel > .ant-col > .ant-card > .ant-card-body > .calendar-view {
  flex: 1;
}

.calendar-panel > .ant-col > .ant-card {
  margin-bottom: 0;
}

.calendar-view .rbc-calendar {
  min-height: 500px;
}

.calendar-view .rbc-event-content {
  font-size: 6px;
  width: 6em;
}

.calendar-events-list .event-content-title {
  color: rgba(0, 0, 0, 0.45);
}

.calendar-event-form .ant-picker {
  width: 100%;
}

.calendar-event-detail .calendar-event-detail-summary table td:first-child,
.calendar-event-detail .calendar-event-detail-participants table td:last-child {
  color: rgba(0, 0, 0, 0.45);
}

.calendar-event-detail .calendar-event-detail-summary table td {
  vertical-align: top;
}

.calendar-event-detail
  .calendar-event-detail-summary
  table
  .event-description
  p:last-child {
  margin-bottom: 0;
}

.calendar-event-detail h3 {
  margin-top: 20px;
}

.calendar-event-detail .ant-avatar {
  margin-right: 6px;
}

.calendar-event-detail .event-response-actions {
  margin-top: 12px;
}

// contacts

.section-sub-menu.ant-menu-horizontal > .ant-menu-item,
.section-sub-menu.ant-menu-horizontal .ant-menu-item:hover,
.section-sub-menu .ant-menu-item.ant-menu-item-selected {
  border-bottom-width: 5px;
}

.helpers-list .helper-list-item-menu,
.resources-list .resource-list-item-menu,
.resources-list-image {
  float: right;
  color: rgba(0, 0, 0, 0.45);
}



.helpers-list .ant-card-meta-detail .ant-card-meta-title,
.resources-list .ant-card-meta-detail .ant-card-meta-title {
  margin-bottom: 0;
}

.helpers-list .helpers-list-contact-list {
  list-style: none;
  margin: 10px 0 10px 9px;
}

.helpers-list .helpers-list-tag-list {
  margin-left: 48px;
}

.helpers-list .ant-card-meta-description .ant-tag {
  margin-left: 10px;
}

.care-guide-helpers-quick-list .contact-info,
.care-guide-invited-quick-list .public-status {
  list-style: none;
}

// resources

.resources-filter .ant-form-item {
  margin-bottom: 0;
}

body .resources-filter .ant-form-item .ant-form-item-label {
  padding-bottom: 0;
}

.resources-list .resources-list-contact-list {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
}

.resources-list .resources-list-contact-list > li > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resources-list .resource-list-tag-list {
  margin-top: 10px;
}

.resources-list{
  margin-top: 10px !important;
}

.resource-notes-list .ant-list-item {
  padding: 0;
}

// user account

.user-account-alerts-form table {
  margin-bottom: 20px;
}

// plans

.ant-card.plan-details .ant-card-meta-title {
  text-align: center;
}

.ant-card.plan-details .plan-selected-icon {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 24px;
}

.ant-card.plan-details .plan-description {
  margin-top: 10px;
}

.ant-card.plan-details .plan-trial-period {
  margin-top: 10px;
  text-align: center;
}

.ant-card.plan-details .plan-price-label {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
}

// stripe

.stripe-payment-form {
  border: 1px solid #d9d9d9;
  padding: 8px;
  margin-bottom: 8px;
}

.stripe-payment-disclaimer {
  font-size: 11px;
  text-align: center;
}

// elements

.telephone-display .anticon,
.email-display .anticon,
.website-display .anticon,
.message-link .anticon,
.website-display-resource .anticon {
  margin-right: 10px;
}

.address-display .anticon {
  float: left;
  margin-top: 3px;
}

.address-display-content {
  margin-left: 24px;
}

.page-actions {
  margin-bottom: 15px;
}

// screen transitions

.screen-fade-enter {
  opacity: 0;
  height: 0;
}

.screen-fade-enter-active {
  opacity: 1;
  transition: opacity 250ms;
  transition-delay: 150ms;
}

.screen-fade-exit {
  opacity: 1;
}

.screen-fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 150ms, transform 150ms;
}

.chat-message .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-top-left-radius: 0.1em;
  border-top-right-radius: 0.1em;
  border-style: ridge;
  background: #fefcfc;
}

.chat-message .ql-toolbar {
  min-height: 0.2em;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.button-group {
  float: right;
}

.button-group button {
  margin-left: 1em;
}

.select-form .ant-select .ant-select-selector {
  border-radius: 0.8em;
}

.input-circle {
  border-radius: 0.8em;
}

.cursor-pointer {
  cursor: pointer;
}

#message_form .recipient-input-row .ant-select-selector,
#calendar_event_form .participant-input-row .ant-select-selector {
  width: 150px;
}

.scroll-participants {
  max-height: 170px;
  overflow-y: scroll;
}

.learning-panel > .ant-col {
  display: flex;
  flex-direction: column;
}

.learning-panel > .ant-col > .ant-card {
  display: flex;
  flex-direction: column;
}

.learning-content-card > .ant-card-body {
  min-height: 100;
  padding: 14px !important;
}

.learning-video {
  width: 100% !important;
  height: 60vw !important;
}

.learning-content-card {
  margin-bottom: 10px;
  border-radius: 20px;
  border: 1px solid;
  border-color: #5399dd !important;
}

.learning-content-card .ant-card-head-title {
  white-space: normal !important;
}

.learning-content-card-bottom {
  margin-bottom: 10px;
  border-radius: 20px;
  font-size: 15px;
}

.learning-content-card-bottom .ant-card-head-title {
  white-space: normal !important;
}

.learning-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
}

.learning-video-container iframe {
  width: 100% !important;
  height: 60vw !important;
}

.learning-content-card-empty {
  border: 1px solid;
  border-color: #5399dd !important;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.button-container-media {
  display: flex;
  gap: 10px;
}

.button-container-page {
  display: flex;
  gap: 10px;
}

.button-container-page .ant-btn > span {
  color: #949494 !important;
}

.learning-list {
  flex: 1 1 auto;
  height: 0;
  float: right;
  font-size: 0.75rem;
  margin: 3px 0 0 10px;
  color: rgba(0, 0, 0, 0.45);
}

.learning-list .ant-list-item-meta-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.learning-list-card {
  border: 1px solid;
  border-color: #5399dd !important;
}

.learning-list-card > .ant-card-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  height: 600;
}

.learning-list .ant-list-item-meta-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learning-select-card {
  margin-bottom: 20px;
}

.learning-tree {
  font-size: 18px;
  height: 700px;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #e3f2fd;
}

.learning-tree-select-card {
  margin-bottom: 20px;
}

.learning-tree-select-card {
  border-radius: 6px 6px 6px 6px;
}

.learning-tree-select > .ant-select-selector {
  border-color: #5399dd !important;
  border-radius: 8px 8px 8px 8px !important;
}

.learning-tree-select > .ant-select-arrow {
  color: #5399dd !important;
}

.calendar-participants {
  height: 210px;
  overflow-y: auto;
  margin-bottom: 8px;
}

.calendar-participants table td:last-child {
  color: rgba(0, 0, 0, 0.45);
}

.care-guide-tag-detail .care-guide-tags-detail {
  color: rgba(0, 0, 0, 0.45);
}

.care-guide-tag-detail .care-guide-tags-detail {
  margin-bottom: 0;
}

.member-profile-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-row {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  .back-link {
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.member-basic-data-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;

  h3 {
    margin: 0;
    line-height: 1.5;
  }
}

.member-basic-data-content > .ant-tag.ant-tag-has-color {
  width: fit-content;
  max-height: 22px;
}

.member-content-panel {
  border-radius: 10px;
  background-color: #fbfbfb;

  .ant-col.ant-form-item-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  .info-subititle {
    font-size: 16px;
    font-weight: 500;
    color: #676767;
    margin: auto 0;
  }

  .edition-mode {
    background-color: #9ccaee6e;
    border: 1px solid #9ccbee;
    padding: 20px;
  }

  .action-btn-row {
    display: flex;
  }

  .action-btn {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 32px;

    span {
      color: #ff4d4f;
      margin: auto 0;
    }
  }

  .ant-btn.ant-btn-danger.action-btn.cancel-outlined-btn {
    background-color: white;
    color: red;
    border-color: red;

    &:disabled {
      background-color: #f5f5f5;
      color: #0000004D;
      border-color: #d9d9d9;

      span {
        color: #0000004D;
      }
    }

    span {
      color: red;
    }
  }

  .ant-btn.ant-btn-primary.action-btn.save-outlined-btn {
    &:disabled {
      background-color: #f5f5f5;
      color: #0000004D;
      border-color: #d9d9d9;
      span {
        color: #0000004D;
      }
    }
  }


  .ant-space {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-row {
      width: 100%;

      p {
        display: flex;
        justify-content: space-between;
        width: 100%;

        span {
          color: #a5a5a5;
        }
      }

      .ant-tag {
        display: grid;
        place-content: center;
        height: 35px;
      }
    }
  }

  .ant-space-item {
    width: 100%;
  }

  .edit-button {
    display: flex;
    height: 40px;
    padding: 6.4px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: medium;
    position: absolute;
    top: 20px;
    right: 20px;

    span {
      padding-top: 2px;
    }
  }

  .cancel-btn {
    background-color: white;
    color: red;
    border-color: red;

    &:disabled {
      background-color: #f5f5f5;
      color: #d9d9d9;
      border-color: #d9d9d9;

      span {
        color: #d9d9d9;
      }
    }

    span {
      padding-top: 2px;
      color: red;
    }
  }


  .edit-button.active {
    background-color: #4279bd;
  }
}

.ant-layout.members-list-layout {
  max-width: 94vw;
  margin: 0 auto;
  margin-top: 10px;

  .ant-typography.section-title {
    margin: 0;
  }
}

.members-list-search-wrapper {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .ant-input-search-with-button {
    max-width: 320px;
  }

  .members-list-filters-button {
    display: flex;
    align-items: center;
    color: black;
    gap: 10px;
  }

  .clear-filters {
    border: 1px solid #4e77b8;

    &:hover {
      opacity: 0.7;
    }

    svg {
      color: #4e77b8;
    }
  }
}

.members-list-table .ant-table-thead > tr > th {
  font-weight: 600;
  text-align: left !important;
}

.members-list-table table td {
  height: 54px;
  padding: 0 16px;
}

.members-list-table {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #5399dd;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-corner {
    background-color: #e5e5e5;
  }

  scrollbar-color: #5399dd #f1f1f1;

  scrollbar-face-color: #5399dd;
  scrollbar-shadow-color: #f1f1f1;
}

.members-list-table {
  flex-grow: 1;
  padding: 0 0 16px 0;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;

      .ant-table {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ant-table-container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          .ant-table-body {
            height: 548px;
            flex-grow: 1;

            .ant-table-placeholder {
              height: 548px;

              .ant-empty.ant-empty-normal {
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .ant-pagination {
    margin: 24px 0 0 0;
  }
}

.members-table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.members-list-selection-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 8px;
  font-size: 16px;

  p {
    margin: auto 0;
  }

  .ant-row {
    align-items: center;
  }

  .ant-btn.event-button {
    height: 40px;

    span {
      font-size: 16px;
      color: white;
    }
  }


  .ant-btn.resource-button {
    height: 40px;

    span {
      font-size: 16px;
      color: white;
    }
  }

  .members-list-elements-shown {
    color: #7b7b7b;
  }
}

.underlined-hyperlink {
  span {
    text-decoration: underline;
  }
}

.ant-select-dropdown {
  .ant-cascader-menu {
    height: fit-content;
  }
}

.header-main-menu-item {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    height: auto;
    width: 16px;
  }
}

.filters-applied-layout {
  margin-bottom: 16px;
}

.action-filter-tag {
  height: 35px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 5px 0 7px;
  gap: 3px;

  button {
    display: flex;
    align-items: center;
    padding: 0;
    height: 14px;
    border: none;
  }
}

.members-list-filters-drawer {
  .ant-drawer-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-collapse-content-box {
    .ant-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }
  }

  .ant-drawer-footer {
    padding: 20px 20px 30px 20px;

    .ant-row {
      gap: 8px;
    }
  }
}

.filter-category > .ant-collapse-header {
  font-size: 18px;
  font-weight: 700;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.error-label {
  font-size: 14px;
  margin-bottom: 1em;
  color: red;
  font-weight: normal;
}

.member-info-column {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .member-data-row > h5 {
    margin-bottom: 5px;
  }

  .info-row {
    padding: 20px;
    h5 {
      margin: 0;
    }
  }

  .info-row.edition-mode {
    background-color: #9ccaee6e;
    border: 1px solid #9ccbee;
    border-bottom: none;
    padding: 20px;
  }

  .info-row.edition-mode.edition-bottom {
    border-bottom: 1px solid #9ccbee;
    border-top: none;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .tag-input {
    margin-bottom: 20px;
  }

  .ant-select {
    .ant-tag {
      width: min-content;
    }
  }

  .ant-select.ant-select-lg.tag-input.ant-select-single.ant-select-show-arrow {
    .ant-select-selector {
      padding: 4px 11px 0px 5px;

      .ant-select-selection-item {
        .ant-tag {
          height: 30px;
        }
      }
    }
  }

  .ant-select.ant-select-lg.ant-select-borderless.tag-input.ant-select-single.ant-select-disabled {
    .ant-select-selector {
      padding: 0;

      .ant-tag {
        margin: 0;
        position: absolute;
        right: 0;
      }
    }
  }

  .member-profile-status-select {
    width: 185px;

    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }
  }
}

.status-badge,
.ant-badge-status {
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
}

.ant-tabs.ant-tabs-top.ant-tabs-mobile.ant-tabs-card.member-section-tabs
  > .ant-tabs-nav::before {
  display: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border-color: transparent;
  border-bottom-color: #e5e5e5;
}

.notes-content-panel {
  padding: 20px 20px;
  border-radius: 10px;
  background-color: #fbfbfb;
  border: 1px solid;
  border-color: #f0f0f0 !important;
}

.note-title {
  padding: 0 0 0 8px;
  color: #4e77b8 !important;
}

.note-pin-button-active {
  background-color: #4e77b8;
  border: 1px solid #5399dd;
  color: #fff;
}

.note-pin-button-disabled {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  color: #c3c3c3;
}

.note-pin-button-disabled:focus {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  color: #c3c3c3;
}

.note-pin-button-active:focus {
  background-color: #4e77b8;
  border: 1px solid #5399dd;
  color: #fff;
}

.note-input-content {
  .ql-container {
    height: 150px;
  }
}

.notes-list-actions {
  margin-top: 10px;

  .ant-input-affix-wrapper {
    max-width: 300px;
  }

  .ant-picker-range {
    max-width: 300px;
  }
}

.notes-list-content-card {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  margin-bottom: 20px;
  padding: 16px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  margin-right: 10px;
}

.note-modal-export {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }

  .ant-modal-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #4e77b8;
  }
}

.care-guide-owners-modal-export {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #4e77b8;
  }
}

.form-note-modal {
  padding: 0px 8px;
}

.form-doc-modal {
  padding: 0px 8px;
}

.export-care-guide-owners-options-title {
  font-size: 18px !important;
}

.export-care-guide-notes-options-title {
  font-size: 18px !important;
}

.icon-button:focus {
  background-color: #fff;
}

.icon-button:hover {
  background-color: #fff;
}

.icon-button-active {
  color: #4e77b8;
}

.icon-button-active:hover {
  color: #c3c3c3;
  background-color: #fff;
}

.icon-button-active:focus {
  color: #4e77b8;
  background-color: #fff;
}

.icon-button-disabled {
  color: #c3c3c3;
}

.icon-button-disabled:hover {
  color: #4e77b8;
  background-color: #fff;
}

.icon-button-disabled:focus {
  background-color: #fff;
}

.icon-transition {
  transition: transform 1s ease;
}

.arrow-right {
  transform: rotate(0deg);
}

.arrow-down {
  transform: rotate(90deg);
}

.arrow-left {
  transform: rotate(180deg);
}

.box {
  transition: all 0.5s ease-in-out;
  padding: "0px 40px";
  
  .ql-snow{
    border: none !important;
    outline: none;
    padding: 0px !important;
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  max-height: 3em;
  color: #484848;
}

.text-expanded {
  -webkit-line-clamp: 36;
  max-height: 36em;
}

.confirmation-modal {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;

    .ant-modal-body {
      padding: 20px 20px 10px 20px;
    }
  }

  .modal-description {
    border-radius: 10px;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    text-align: center;

    .modal-description-icon {
      color: #bb2118;
      width: 48px;
      height: auto;
      padding-bottom: 20px;
    }

    .modal-description-title {
      color: #676767;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .modal-description-text {
      color: #676767;
      font-size: 16px;
      margin-bottom: 1rem;
    }

    .cancel-btn.ant-btn.ant-btn-danger > span {
      color: #fff;
    }
  }

  .ant-modal-header {
    border-radius: 10px !important;
    border-bottom: none;
  }

  .ant-modal-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #4e77b8;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 10px 20px 20px 20px;
  }
  
}

.custom-notification {
  border-radius: 10px !important;
  height: fit-content;
  width: fit-content;
  padding: 10px 12px;
  display: flex;
  align-items: center;

  .ant-notification-notice-message {
    margin: 0;
  }

  .ant-notification-notice-close {
    color: #fff;
    top: 11px;
    right: 12px;
  }
}

.custom-notification-error {
  background-color: #F44336;

  .ant-notification-notice-message {
    color: #fff;
  }
}

.custom-notification-success {
  background-color: #22C55E;

  .ant-notification-notice-message {
    color: #fff;
  }
}

.member-profile-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-form-item {
    margin-bottom: 0px;
  }
}

.care-guide-files-layout {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #fbfbfb;
  padding: 20px;
  max-width: 94vw;
}

.user-documents-action-bar {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;

  .ant-typography {
    font-size: 25px;
    font-weight: 600;
    color: #4e77b8;
    margin: 0;
  }

  .search-input {
    width: 320px;
  }

  .export-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 135px;

    span {
      color: black;
    }
  }
}

.care-guide-files-table {
  min-height: 500px;

  .ant-table-thead > tr > th {
    font-weight: 600 !important;
    background-color: #fff !important;
  }
}

.export-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 135px;

  span {
    color: black !important;
  }
}

.export-btn:hover {
  span {
    color: #4e77b8 !important;
  }
}

.export-btn:focus {
  span {
    color: #4e77b8 !important;
  }
}

.field-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.doc-title {
  padding: 0 0 0 8px;
  color: #4e77b8 !important;
}

.btn-save {
  &:disabled {
    span {
      color: #d9d9d9 !important;
    }
  }
}

.member-document-table-menu-item {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    font-size: 16px;
  }
}

.website-display-resource {
  max-width: 500px;
}

.resources-list-image {
  margin-bottom: 16px;
  padding-top: 6px;
}

.resources-list-image .ant-image {
  margin-right: 10px;
}

.resources-list-image .ant-image .ant-image-img {
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}


.resources-card-image {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.react-icon-ri {
  padding-top: '2px' !important;
}

.document-modal-export {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }

  .ant-modal-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #4e77b8;
  }
}


.note-content-readonly .ql-snow {
    border: none !important;
    outline: none;
    padding: 0px !important;
  }


.note-content-readonly .ql-editor {
  padding: 0 !important;
}

.note-content-readonly .ql-blank::before {
  left: 0 !important;
  right: 0 !important;
}

.resource-name{
  font-weight: bold;
}


.resource-title {
  color: #4e77b8 !important;
}

.resource-form-content-panel {
  margin-top: 20px;
  padding: 40px 40px 0px 40px;
  border-radius: 10px;
  background-color: #fbfbfb !important;
  border: 1px solid;
  border-color: #f0f0f0 !important;
}

.resource-upload-file{
  .ant-upload-list-item{
    border-color: #22c55e;
    background-color: #FFFFFF;
  }
}

.resource-upload-file:hover{
  .ant-upload-list-item{
    border-color: #22c55e;
    background-color: #FFFFFF;
  }
}

.resource-form-admin-input-content{
  .ql-editor {
    height: 120px;
  }
  .ql-container {
    border: 1px solid #d9d9d9
  }
}

.cancel-btn-resource {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-color: #434343 !important;

  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.cancel-btn-resource:hover {
  border-color: #434343 !important;

  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.cancel-btn-resource:focus {
  border-color: #434343 !important;
  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.previous-btn {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-color: #434343 !important;

  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.previous-btn:hover {
  border-color: #434343 !important;

  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.previous-btn:focus {
  border-color: #434343 !important;
  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.resource-pin-active {
  color: #4e77b8;
  margin-right: 14px;
}

.resource-pin-active:hover {
  color: #c3c3c3;
  background-color: #fff;
  cursor: pointer;
}

.resource-pin-active:focus {
  color: #4e77b8;
  background-color: #fff;
}

.resource-table-menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.resource-table-menu-item svg {
  font-size: 16px; 
  height: 16px;
  width: 16px;
}


.confirm-modal-btn-danger:hover {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  span {
    color: #fff !important;

  }
}
.confirm-modal-btn-danger:focus {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  span {
    color: #fff  !important;

  }
}



.resource-list-table .ant-table-thead > tr > th {
  font-weight: 600;
  text-align: left !important;
}

.resource-list-table table td {
  height: 54px;
  padding: 0 16px;
}

.resource-list-table {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #5399dd;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-corner {
    background-color: #e5e5e5;
  }

  scrollbar-color: #5399dd #f1f1f1;

  scrollbar-face-color: #5399dd;
  scrollbar-shadow-color: #f1f1f1;
}

.resource-list-table {
  flex-grow: 1;
  margin: 0 0 16px 0;
  padding: 0 0 16px 0;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;

      .ant-table {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ant-table-container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          .ant-table-body {
            height: 548px;
            flex-grow: 1;

            .ant-table-placeholder {
              height: 548px;

              .ant-empty.ant-empty-normal {
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .ant-pagination {
    margin: 24px 0 0 0;
  }
}

.resource-list-table-header .ant-btn.ant-btn-dangerous > span {
  color: red;
}

.resource-list-table-header .resource-list-table-header-row {
  margin-bottom: 20px !important;
}

.resource-list-table-header .ant-typography {
  margin-bottom: 0 !important;
}

.box-resource {
  max-height: 280px;
  overflow-y: auto;
  transition: all 0.5s ease-in-out;
  margin-bottom: 10px;

  .ql-editor{
    padding: 12px 0 !important;

  }
  .ql-snow{
    border: none !important;
    outline: none;
    padding: 0px !important;
  }
}


.resource-preview-title {
  padding: 0 0 0 8px;
  color: #4e77b8 !important;
}

.resource-preview {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }

  .ant-modal-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #4e77b8;
  }
  
}

.resource-card {
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
  padding: 10px 16px 10px 16px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 400px;
}

.resource-card.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.resource-card .actions,
.resource-card .actions * {
  pointer-events: auto; 
  opacity: 1; 
}

.resource-list-table .actions,
.resource-list-table .actions * {
  pointer-events: auto; 
  opacity: 1; 
}

.disabled-row {
  opacity: 0.5;
  background-color: #fbfbfb;
}

.resource-list-tag-card {
  cursor: pointer;
}

.resource-list-tag-card > *:not(:last-child) {
  margin-bottom: 6px;
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.icon-active{
  color: #4e77b8
}

.icon-inactive{
  color: #A5A5A5
}

.resource-list-header .ant-btn.ant-btn-dangerous > span {
  color: red;
}

.resource-list-header .resource-list-header-row {
  margin-bottom: 20px !important;
}

.resource-screen {
  margin: 0 30px;
}

.resource-list-description {
  cursor: pointer;
  width: 100%; 
  max-height: 88px;
  flex-wrap: wrap; 
  overflow-y: hidden !important;
  margin-top: 6px;

  .ql-editor{
    padding: 0 0 !important;
    cursor: pointer;
    max-width: 100%; 
    width: auto !important;
    overflow-y: hidden !important;
  }

  .ql-editor p{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }

  .ql-snow{
    border: none !important;
    outline: none;
    padding: 0px !important;
  }
}


.resource-list-description-expanded {

  .ql-editor p{
    -webkit-line-clamp: 3;
  }
}

.resource-list-description-preview {
  cursor: pointer;
  width: 100%; 

  .ql-editor{
    padding: 0 !important;
    cursor: pointer;
    max-width: 100%; 
    width: auto !important;
  }

  .ql-snow{
    border: none !important;
    outline: none;
    padding: 0px !important;
  }
}

.resource-note-modal-title {
  color: #4e77b8 !important;
  margin-bottom: 0 !important;
  margin-right: 8px !important;
}

.resource-note-modal{
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }

  .resource-note-form-add-body {
    .ql-editor{
      height: 144px !important;
    }
  }

  .resource-note-form-edit-body {
    .ql-editor{
      height: 80px !important;
    }
  }


  .button-container {
    display: flex;
    justify-content: flex-end; /* Alinea el botón a la derecha */
  }
  
  .resource-notes-list-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    color: #D9D9D9;
  }

}


.resource-note-modal
  .ant-comment-content-author
  > span.ant-comment-content-author-time {
  color: #999;
}


.resource-form-modal-title {
  color: #4e77b8 !important;
  margin-bottom: 0 !important;
}

.resource-form-modal{
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }
  
}

.info-container {
  margin-top: 10px;
  max-width: 100%;
}

.info-container > *:not(:last-child) {
  margin-bottom: 4px;
}

.resource-form{
  margin: 0 20px;
}


.resource-form-btn {
  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.resource-form-btn:hover {
  border-color: #434343 !important;

  span {
    color: #434343 !important;
    border-color: #434343 !important;
  }
}

.text-overflow {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
}

.webkit-line-clamp-one {
  -webkit-line-clamp: 1 !important;
}

.webkit-line-clamp-three {
  -webkit-line-clamp: 3 !important;
}

.resource-card-title {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  -webkit-line-clamp: 2 !important;
  margin-bottom: 2px;
  cursor: pointer;
  font-size: 16px !important;
}

.resource-card-title:hover {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  -webkit-line-clamp: 3 !important;
}


.btn-large-round{
  border-radius: 8px;
}

.outer-container {
  position: relative;
  padding: 20px; 
  background: linear-gradient(to right, #4177BA 49%, white 49%, white 51%, #F49120 51%);
  border-radius: 40px;
  overflow: hidden; 
}

.inner-container {
  padding: 30px; 
  background: white; 
  border-radius: 20px;
  padding-left: 50px;
  padding-right: 50px;
}


.btn-view-active {
  background: #C3C3C3;
  border-color: #F1F1F1;
  height: 40px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-view-desactive {
  background: #F6F6F6;
  border-color: #F1F1F1;
  height: 40px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-view-active:hover{
  background: #C3C3C3;
  border-color: #F1F1F1;
}

.btn-view-active:focus{
  background: #C3C3C3;
  border-color: #F1F1F1;
}

.btn-view-desactive:hover{
  background: #F6F6F6;
  border-color: #F1F1F1;
}

.btn-view-desactive:focus{
  background: #F6F6F6;
  border-color: #F1F1F1;
}

.responsive-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  word-break: break-all !important;
  overflow-wrap: break-word !important;
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.icon-container {
  display: flex;
  align-items: center;
}


.resource-filters-title {
  color: #4e77b8 !important;
}

.resource-filters {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;

    .ant-typography{
      margin: 0;
    }
  
  }

  .ant-modal-body {
    padding: 0 24px;
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #4e77b8;
  }
  
}

.section-daily-log {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .info-col {
    padding: 20px;
    h5 {
      margin: 0;
    }
  }

}

.info-daily-log {
  border-radius: 10px;
  background-color: #fbfbfb;
  width: 100%;
}

.alert-waiting-socket {
  border-radius: 10px;
  background-color: #f28f00;

  .ant-alert-message {
    font-size: 14px;
    color:white
  }
}

.dashboard-admin-title {
  color: #4e77b8 !important;
  margin-bottom: 0.1rem !important;
}

.event-upcoming-card {
  background-color: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.15);
}

.care-members-card {
  background-color: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.15);
}

.event-upcoming-item {
  border-radius: 6px !important;
  width: '100%';
  height: '58px';
  background-color: white;
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 10px; 
  margin-top: 16px;
  padding-left: 20px;
}

.event-upcoming-item::after {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px; 
  left: 6px; 
  width: 5px; 
  background-color: #5399DD; 
  border-radius: 10px;
}

.care-member-item {
  border-radius: 6px !important;
  width: '100%';
  height: '58px';
  background-color: white;
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 10px; 
  margin-top: 16px;
}

.section-daily-dashboard {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  .info-row {
    padding-top: 20px;
    h5 {
      margin: 0;
    }
  }

}

.recommended-utilities-list-admin {
  .ant-list-item {
    border-bottom: none !important;
  }
}

.recommended-utilities-list {
  .ant-list-item {
    border-bottom: none !important;
    padding: 6px 0;
  }
}


.section-card {
  background-color: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}


.recommended-utility-modal {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }

  .ant-modal-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #4e77b8;
  }
}

.recommended-utility-card {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
  background-color: white;
}

.section-resource-card-admin-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.section-resource-card-admin-dashboard {
  background-color: white;
  border-radius: 2px;
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 200px;
}

.section-resource-card-admin {
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #00000012;
  min-height: 200px;
}

.section-resource-card-admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.section-resource-card-admin-plus-text {
  font-size: 20px;
  color: #4279BD;
}


.section-resource-card-admin-list-title {

  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  word-break: break-all !important;
  overflow-wrap: break-word !important;

}

.section-resource-card-admin-list-description {

    width: 100%; 
    flex-wrap: wrap; 
    max-height: 22px;

    overflow-y: hidden !important;
  
    .ql-editor{
      padding: 0 0 !important;
      cursor: pointer;
      max-width: 100%; 
      width: auto !important;
      overflow-y: hidden !important;
    }
  
    .ql-editor p {
      display: -webkit-box !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
    }  
  
    .ql-snow {
      border: none !important;
      outline: none;
      padding: 0px !important;
    }

}

.section-resource-card-admin-modal {
  
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-header {
    border-radius: 10px !important;
  }

  .ant-modal-title {
    margin-bottom: 0;
  }
  .ant-modal-body {
    padding: 16px 24px;
  }
}

.section-resource-card-admin-list-items {
  margin-bottom: 10px;
  margin-top: 10px;

  .ant-list-item {
    border-bottom: none;
    padding: 0 0 16px;
  }

  .ant-list-item:last-child {
    border-bottom: none !important;
  }
  
  .ant-list-pagination {
    margin-top: 0;
  }
}

.add-resources-dashboard-title {
  padding: 0 0 0 2px;
  color: #4e77b8 !important;
  margin-bottom: 0 !important;
}


.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px;
}

.item {
  background-color: #4CAF50;
  color: white;
  padding: 20px;
  text-align: center;
}



.section-resource-card-list-title {

  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  word-break: break-all !important;
  overflow-wrap: break-word !important;

}

.section-resource-card-list-description {

  width: 100%; 
  flex-wrap: wrap; 
  max-height: 22px;

  overflow-y: hidden !important;

  .ql-editor{
    padding: 0 0 !important;
    cursor: pointer;
    max-width: 100%; 
    width: auto !important;
    overflow-y: hidden !important;
  }

  .ql-editor p {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }  

  .ql-snow {
    border: none !important;
    outline: none;
    padding: 0px !important;
  }

}


.dashboard-invitations-list {
  background-color: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.15);
}

.dashboard-events-invitations-list {
  background-color: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.15);
}

.dashboard-invitations-quick-list {
  background-color: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.15);
}

.invitations-list-item {
  border-radius: 6px !important;
  width: '100%';
  background-color: white;
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding-left: 8px;
}

.event-invitations-list-item {
  border-radius: 6px !important;
  width: '100%';
  background-color: white;
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding-left: 8px;
  
  .ant-list-item-meta {
    margin-bottom: 8px;
  }

  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
}


.section-resource-card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}


.section-resource-card-dashboard {
  background-color: white;
  border-radius: 2px;
  overflow: hidden; 
  min-height: 200px;
  min-width: 280px;
  height: 200px;
  flex: 1 0 30%;
}

.daily-log-date-picker {
  .ant-picker-panel-container {
    margin-left: 0 !important;
  }
}

@primary-color: #4E77B8;