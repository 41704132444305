// Responsive Styles


@media (max-width: 330px) {

  .badge-menu-mobile{
    display: none;
  }
  .website-display-resource{
    max-width: 200px;
  }

  .resource-name{
    max-width: 170px;
  }
  
}

@media (min-width: 330px) {

  .website-display-resource{
    max-width: 280px;
  }
  
  .resource-name{
    max-width: 220px;
  }

}

@media (min-width: 440px) {

  .resource-name{
    max-width: 350px;
  }

}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .page-header .care-guide-selector {
    display: inline-block;
  }

  .ant-modal .journal-post-form .rating-input .ant-form-item-label label {
    max-width: 230px;
  }

  .journal-summary .ant-card-meta-description {
    font-size: 1em;
  }

  .calendar-view .rbc-event-content {
    font-size: 10px;
  }
  .learning-list-card {
    margin-bottom: 10px !important;
  }  

  .learning-image{
    width: 100% !important;
  }


  .website-display-resource{
    max-width: 480px;
  }

  .resource-name{
    max-width: 400px;
  }
  
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  body .ant-form-item .ant-form-item-label {
    padding-bottom: 8px;
  }

  .journal-post-form .journal-tags-container {
    margin-bottom: 0;
  }

  .journal-post-form .rating-input .ant-form-item-label label {
    max-width: 260px;
  }

  .calendar-view .cv-rbc-toolbar .cv-rbc-btn-group.calendar-btns-view {
    display: inline-block;
  }

  .calendar-view .rbc-event-content {
    font-size: 12px;
    width: 7em;
  }

  .learning-video{
    width: 100% !important;
    height: 50vw !important; 
  }
  
  .learning-video-container iframe {
    width: 100% !important; 
    height: 50vw !important; 
  }
  
  .learning-image{
    width: 100% !important;
  }

  .learning-content-card-bottom{
    margin-bottom: 10px;
    border-radius: 20px;
    font-size: 18px;
  }

  .img-logo{
    height: 42px;
  }

  .website-display-resource{
    max-width: 480px;
  }

  .resource-name{
    max-width: 500px;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .journal-posts-screen .content-sidebar {
    display: block;
  }

  .journal-posts-screen .inline-filter {
    display: none;
  }

  .journal-post,
  .journal-emojis,
  .journal-tags,
  .journal-media,
  .journal-post-replies {
    margin-left: 48px;
  }

  .journal-post-replies .journal-reply-form .ant-btn {
    margin-left: 44px;
  }

  .calendar-view .rbc-event-content {
    width: 10em;
  }

  .learning-list-card {
    margin-right: 20px;
    margin-left: 30px;
  }

  .learning-video{
    width: 100% !important;
    height: 30vw !important; 
  }
  
  .learning-video-container iframe {
    width: 100% !important; 
    height: 30vw !important; 
  }


  .website-display-resource{
    max-width: 480px;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .calendar-view .rbc-event-content {
    width: 12em;
  }

  .journal-post-form .rating-input .ant-form-item-label label {
    max-width: 390px;
  }

  .website-display-resource{
    max-width: 480px;
  }
}

@primary-color: #4E77B8;