@media (min-width: 0px) {
    .calendar-view .rbc-event-content {
        font-size: 13px !important;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .calendar-view .rbc-event-content {
        font-size: 13px !important;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .calendar-view .rbc-event-content {
        font-size: 12px !important;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .calendar-view .rbc-event-content {
        font-size: 12px !important;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .calendar-view .rbc-event-content {
        font-size: 12px !important;
    }
}
@primary-color: #4E77B8;