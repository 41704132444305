.note-input-content .ql-snow {
  border: none !important;
  outline: none;
  padding: 0px !important;
}

.note-input-content .ql-editor {
  padding: 0 !important;
}

.note-input-content .ql-blank::before {
  left: 0 !important;
  right: 0 !important;
}

.note-input-content-column {
  .ql-snow{
    border: none !important;
    outline: none;
    padding: 0px !important;
  }
}

#toolbar-care-guide-note .ql-formats {
  margin-right: 0px !important;
  padding: 6px  0px !important;

}

#toolbar-care-guide-note .format-divider {
  height: 1.8em;
  color: black;
}

@primary-color: #4E77B8;