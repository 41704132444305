.payment-form-plan .plans-list .ant-card.selected {
  border-color: @primary-color;
}

.payment-form-actions {
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
}

.payments-powered-by img {
  display: block;
  height: 35px;
  margin-bottom: 10px;
}

.stripe-payment-contact-support {
  text-align: center;
}

.subscription-settings .ant-tag:last-of-type {
  margin-right: 0;
}

.screen-register .welcome-box {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  border: 2px solid #4078bd;
  padding: 20px 30px;
  text-align: center;
}

.screen-register .welcome-box .circle-logo img {
  height: 150px;
  margin-bottom: 20px;
}

.screen-register .welcome-box h2 {
  color: #4078bd;
  font-size: 34px;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.screen-register .welcome-box .welcome-box-copy {
  color: #4078bd;
  font-size: 26px;
  line-height: 1.3em;
  font-weight: 500;
  margin-bottom: 30px;
}

.screen-register .welcome-box .welcome-box-copy a {
  text-decoration: underline;
}

.screen-register .welcome-box .ant-btn-primary {
  background-color: #F59321;
  border-color: #F59321;
}

.screen-register .welcome-box .ant-btn-primary:hover {
  background-color: #f9a23e;
  border-color: #f9a23e;
}

.screen-register .welcome-box .ant-btn-primary > a {
  color: #fff;
}

@primary-color: #4E77B8;