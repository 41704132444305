.container-form-login,
.container-form-2fa {
  padding-top: 25px;
  padding-bottom: 20px;
}

.container-form-login .form-actions .ant-form-item,
.container-form-2fa .form-actions .ant-form-item {
  text-align: right;
}

.container-form-login .remember-username-row {
  margin-bottom: 4px;
}

.container-form-login .remember-row {
  margin-bottom: 12px;
}

.container-form-login .link-forgot-password {
  float: right;
}

.link-forgot-password{
  font-weight: bold;
}

.link-register{
  font-weight: bold;
}

.container-form-login .register-link {
  text-align: center;
  border-top: 1px solid #E9E9E9;
  padding-top: 18px;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.5);
}

.container-form-login .auth-logo,
.container-form-2fa .auth-logo {
  display: block;
  margin: 0 auto 60px auto;
  height: 35px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-form-login,
  .container-form-2fa {
    padding-top: 125px;
  }

  .container-form-login .auth-logo,
  .container-form-2fa .auth-logo {
    height: 42px;
    margin-bottom: 20px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
@primary-color: #4E77B8;