.back-btn {
  font-size: 20px;
}

.chat-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: auto;
  gap: 30px;
  h3 {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    color: #4279bd;
  }
  p {
    margin: 0;
    font-size: 16px;
  }

  a {
    width: fit-content;
    margin: 0 auto;
  }
}

@primary-color: #4E77B8;