.password-reset-request-button, .password-reset-button {
  margin-right: 15px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

@primary-color: #4E77B8;